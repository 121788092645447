.App {
  text-align: center;
}

.App {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  /* allow footer to float to bottom */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* make footer sticky to bottom */
.App>footer {
  margin-top: auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bmc-logo {
  vertical-align: middle;
}

@media screen and (min-width: 1024px) {
  .bmc-menu-text {
    display: none;
  }
}

.cap-width {
  max-width: 50vw;
  margin: auto;
}

@media screen and (max-width: 768px) {
  li:not(.is-active) > a > span:not(.icon) {
    visibility: hidden;
    position:  absolute;
  }

  nav.tabs li:not(.is-active) {
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -ms-flex-negative: 1;
    flex-shrink: 1;
  }

  .tabs .icon {
    margin-left: 0.5em;
  }

  table {
    font-size: .9em;
  }

  .cap-width {
    max-width: 70vw;
  }
}

.tabs li {
  transition: flex-grow 1s ease;
}

.has-text-weight-super-bold {
  font-weight: 900 !important;
}

.light-bg {
  background-color: #f8f5f5;
}

.white-bg {
  background-color: #ffffff;
}

.details-section {
  padding-top: 1.5rem;
}

.no-wrap {
  white-space: nowrap;
}

.card .divider.is-vertical {
  height: 260px;
}

.card .divider.is-vertical::before {
  margin-bottom: 0;
}

.card .divider.is-vertical::after {
  margin-top: 0;
}

.card .divider:not(.is-left)::before {
  margin-right: 0;
}

.card .divider:not(.is-right)::after {
  margin-left: 0;
}

a.space-left::before,
a.space-both::before,
a.space-both::after,
a.space-right::after {
  content: " ";
}
