.device {
    position: relative;
    display: inline-block;
    width: 80vw;
    height: calc(80vw * 493 / 1518);
}

.card-content .device {
    width: 50vw;
    height: calc(50vw * 493 / 1518);
}

@media screen and (max-width: 768px) {
    .card-content .device {
        width: 70vw;
        height: calc(70vw * 493 / 1518);
    }    
}

.device .core {
    /* (14, 6) origin offset */
    /* w*h: 1518px x 493px */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.device .display {
    /* (38, 94) origin with offset */
    position: absolute;
    top: calc(100% * 94 / 493);
    left: calc(100% * 38 / 1518);
    width: 94.2688%;
    height: 62.2718%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow: hidden;
}

.device .display .pixel {
    /* w*h: 35.85px x 35.85px */
    height: calc(100% * 1 / 8);
    width: calc(100% * 1 / 37);
}

.pixel.on {
    background-color: #f0f0f0;
}

.pixel:not(.on) {
    background-color: #141414;
}

.device .frame {
    /* (36, 92) origin with offset */
    /* w*h: 1435px x 311px */
    position: absolute;
    top: calc(100% * 92 / 493);
    left: calc(100% * 36 / 1518);
    width: calc(100% * 1435 / 1518)
}
